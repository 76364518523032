import { gql } from 'nuxt-graphql-request'

export const Alternates = gql`
  fragment Alternates on EntryInterface {
    alternates {
      url
      language
      permalink
      is_root
    }
  }
`

export const SEO = gql`
  fragment SEO on EntryInterface {
    seo_title
    seo_default_title_deactivated
    seo_image {
      ...Asset
    }
    seo_hidden
    seo_description
  }
`

export const Asset = gql`
  fragment Asset on AssetInterface {
    url
    ... on Asset_Assets {
      id
      alt
      permalink
      url
      extension
      is_image
      width
      height
      focus_css
    }
  }
`

export const BlogPostCard = gql`
  fragment BlogPostCard on EntryInterface {
    ... on Entry_BlogPosts_BlogPost {
      slug
      uri
      is_big
      intro
      title
      date(format: "d.m.Y")
      blog_categories {
        ... on Term_BlogCategories_BlogCategories {
          title
          slug
        }
      }
      image {
        ...Asset
      }
    }
  }
`

export const SwisstainableDetailpagePreview = gql`
  fragment SwisstainableDetailpagePreview on EntryInterface {
    ... on Entry_SwisstainableHub_SwisstainableHubDetailpage {
      slug
      id
      uri
      intro
      title
      date(format: "d.m.Y")
      swisstainable_hub_categories {
        ... on Term_SwisstainableHubCategories_SwisstainableHubCategory {
          id
          title
          slug
        }
      }
      swisstainable_hub_topics {
        ... on Term_SwisstainableHubTopics_SwisstainableHubTopic {
          id
          title
          slug
          topic_area {
            id
            slug
            title
          }
        }
      }
      image {
        ...Asset
      }
    }
  }
`

export const Testimonial = gql`
  fragment Testimonial on EntryInterface {
    ... on Entry_Testimonials_Testimonials {
      title
      job_title
      text
      image {
        ...Asset
      }
    }
  }
`

export const TeamMemberCard = gql`
  ... on Entry_Team_TeamMember {
    title
    job_title
    additional_text
    second_additional_text
    email
    phone
    is_chairman
    image {
      ...Asset
    }
  }
`

export const HeroHomepage = `
  title
  text
  image {
    ...Asset
  }
`

export const HeroDetailpage = `
  title
  text
  image {
    ...Asset
  }
`

export const HeroContact = `
  title
  text
`

export const HeroContentpage = `
  title
  text
  image {
    ...Asset
  }
`

export const HeroSimple = `
  title
  text
`

export const Heros = `
  heros {
    ... on Set_Heros_HeroHomepage {
      ${HeroHomepage}
    }
    ... on Set_Heros_HeroDetailpage {
      ${HeroDetailpage}
    }
    ... on Set_Heros_HeroContact {
      ${HeroContact}
    }
    ... on Set_Heros_HeroContentpage {
      ${HeroContentpage}
    }
    ... on Set_Heros_HeroSimple {
      ${HeroSimple}
    }
  }
`

export const PageHero = gql`
  fragment PageHero on EntryInterface {
    ... on Entry_Pages_Page {
      slug
      ${Heros}
    }
    ... on Entry_Pages_Team {
      slug
      ${Heros}
    }
    ... on Entry_BlogPosts_BlogPost {
      id
      intro
      title
      image {
        ...Asset
      }
    }
  }
`

export const EventsPreview = gql`
  fragment EventsPreview on EntryInterface {
    ... on Entry_Events_EventsDetail {
      slug
      url
      title
      from
      to
      link_external_content
      content_source {
        value
      }
      event_topic {
        title
        slug
      }
      event_locations {
        title
        slug
      }
      event_categories {
        title
        slug
      }
      event_time {
        title
        slug
      }
    }
  }
`
